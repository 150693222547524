import "./BillPaying.css";
import paymentImg from "../containers/images/transaction.png";
import HandR from "../containers/images/Hand Right.png";
import AppleImage from "../../Assets/AppleStore.png";
import GoogleImage from "../../Assets/GoogleStore.png";

const BillPaying = () => {
  return (
    <div className="bg-wrapper">
      <div className="home-bg">
        <p id="bg-font">The bill-Paying app for everyone.</p>
        <p id="smaller-font">
          Convert your airtime to cash, buy airtime and data at a very
          affordable rate instantly at any time of the day with{" "}
          <strong>MegazApp.</strong>
        </p>
        <div className="div-app-badges">

        <a href="https://apps.apple.com/ng/app/megazapp/id6590635149" target="_blank" rel="noopener noreferrer">
          <img src={AppleImage} alt="apple" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.megazapp.megaz_pay" target="_blank" rel="noopener noreferrer">
          <img src={GoogleImage} alt="google" />
        </a>
          
        </div>
        <div className="thumb">
          <img src={HandR} id="HandRight" alt="HandRight" />

          <p>New to MegazApp?</p>
          <a href="/create" className="blue-btn">
            Create Account
          </a>
        </div>
      </div>

      <div className="imgCont">
        <div id="first-circle">
          <div id="second-circle">
            <div id="third-circle"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillPaying;
