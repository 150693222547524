import "./Contact.css";
import FaceBook from "../../images/svgs/ContFB.svg";
import Instagram from "../../images/svgs/ContIG.svg";
import XTwitter from "../../images/svgs/Xtwitter.svg";
import WhatsApp from "../../images/svgs/ContWT.svg";
import AppleImage from "../../Assets/AppleStore.png";
import GoogleImage from "../../Assets/GoogleStore.png";
const Contact = () => {
  return (
    <div className="contact-body">
      <div className="contact-us">
        <h1>Contact Us</h1>
        <p className="call-us">
          <span className="text-wrapper">Call us:</span>
          <span className="span">
            {" "}
            (+234) 8073019824
            <br />
            <br />
          </span>
          <span className="text-wrapper">Email us:</span>
          <span className="span">
            {" "}
            support@megazapp.co
            <br />
            <br />
          </span>
          <span className="text-wrapper">Address:</span>
          <span className="span">
            {" "}
            PLOT A76 MAURICE NWOFORCHA STREET, BRAINS AND HAMMERS CITY ESTATE, ABUJA
            <br />
            <br />
          </span>
          <div className="media-flex">
            <span className="text-wrapper">Follow/Contact us: </span>
            <span className="social_media_cont">
              <a href="https://web.facebook.com/profile.php?id=61554649462963" target="_blank" rel="noopener noreferrer">
                <img className="media" src={FaceBook} alt="facebook" />
              </a>
              <a href="https://www.instagram.com/megaz_app/?hl=en" target="_blank" rel="noopener noreferrer">
              <img className="media" src={Instagram} alt="instagram" />
              </a>
              <a href="https://x.com/Megaz_app" target="_blank" rel="noopener noreferrer">
              <img className="media" src={XTwitter} alt="xtwitter" />
              </a>
              <a href="https://wa.me/2348073019824" target="_blank" rel="noopener noreferrer">
              <img className="media" src={WhatsApp} alt="whatsapp" />
              </a>
            </span>
          </div>
        </p>
        <div className="app-link">

          <img src={AppleImage} alt="apple" />
          <img src={GoogleImage} alt="google" />
          
        </div>
      </div>


      <div className="cont-form">
        <div className="form-div">
          <input type="text" className="text-input" placeholder="Full name" />
          <input
            type="email"
            className="text-input"
            placeholder="Email Addresss*"
          />
          <input
            type="text"
            className="text-input"
            placeholder="Phone number (Optional)"
          />
          <input
            type="text"
            className="text-input"
            placeholder="Reason for contact"
          />
          <textarea
            type="text"
            className="text-input-message"
            placeholder="Message*"
          />
          <a href="#" className="message-btn">
            Submit Message
          </a>
        </div>
      </div>
    </div>
  );
};
export default Contact;
